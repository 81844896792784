var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"color":"#00695C","dark":""}},[_c('v-app-bar-nav-icon'),_c('v-toolbar-title',[_vm._v("Lịch trực")]),_c('v-spacer'),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.searhBox),expression:"searhBox"}],attrs:{"append-icon":"mdi-magnify","label":"Tìm kiếm","clearable":"","single-line":"","hide-details":""},on:{"blur":function($event){_vm.searhBox = false}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.searhBox),expression:"!searhBox"}],attrs:{"icon":""},on:{"click":function($event){_vm.searhBox = true}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.themPhongBan}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-playlist-plus")])],1)]}}])},[_c('span',[_vm._v("Thêm lịch trực")])])],1),(_vm.loadingList)?_c('v-layout',{staticClass:"d-flex justify-center pt-8 pb-8"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_c('v-list',{attrs:{"subheader":"","two-line":""}},[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Danh sách lịch trực")]),_vm._l((_vm.diaDiems),function(diaDiem){return _c('v-list-item',{key:diaDiem.title},[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"grey lighten-1",attrs:{"dark":""}},[_vm._v(" mdi-calendar")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(diaDiem.loai ? diaDiem.loai.name : '')}}),_c('v-list-item-subtitle',[_vm._v(_vm._s("Thời gian: " + _vm.formatDate(diaDiem.bat_dau) + " - " + _vm.formatDate(diaDiem.ket_thuc)))]),_c('v-list-item-subtitle',[_vm._v("Trưởng ca: "+_vm._s(diaDiem.truong_ca_truc))])],1),_c('v-list-item-action',[(
            new Date(diaDiem.bat_dau) <= Date.now() &&
            new Date(diaDiem.ket_thuc) >= Date.now()
          )?_c('v-chip',{attrs:{"color":"success","dark":"","small":""}},[_vm._v(" Ngày trực hôm nay ")]):_vm._e(),(new Date(diaDiem.bat_dau) > Date.now())?_c('v-chip',{attrs:{"color":"primary","dark":"","small":""}},[_vm._v(" Chưa đến ngày trực ")]):_vm._e(),(new Date(diaDiem.ket_thuc) < Date.now())?_c('v-chip',{attrs:{"color":"pink","dark":"","small":""}},[_vm._v(" Ngày trực đã qua ")]):_vm._e()],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.exportExcel(diaDiem.id)}}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-microsoft-excel")])],1)]}}],null,true)},[_c('span',[_vm._v("Xuất excel")])])],1),_c('v-list-item-action',{staticClass:"ml-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.editDiaDiem(diaDiem)}}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Chi tiết")])])],1)],1)}),_c('div',{staticClass:"pt-2"},[_c('v-pagination',{attrs:{"circle":"","length":_vm.pageCount,"total-visible":6},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_vm._v(">")])],1)],2),_c('create-edit',{ref:"diadiem",on:{"on-done":_vm.getDanhSachDiaDiem,"export-excel":_vm.exportExcel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }